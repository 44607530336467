body {
  margin: 0;
  
  
}
html{
  background-color: #ffffff;
  
  }

#root{
  background-color: #ffffff;
min-height: 100%;

/* background:linear-gradient(0deg, rgba(89,99,101, 0.9), rgba(89,99,101,0.9)), url("./assets/victoria-shes-Qa29U4Crvn4-unsplash.jpg");
  background-size:cover; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
